import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import servicesStyles from "../styles/pages/services.module.css"

import postProduction from "../images/post-production.png"
import contentCreation from "../images/content-creation.png"
import contentBranding from "../images/content-branding.png"
import talentCasting from "../images/talent-casting.png"
import inFilmAdvertising from "../images/in-film-advertising.png"
import eventMarketing from "../images/event-marketing.png"

const ServicesPage = () => (
  <Layout location="services">
    <SEO title="Services" />
    <div className={ servicesStyles.postProdSection }>
        <div className={ servicesStyles.title }>
            <img src={ postProduction } alt=""/>
            <h3>Post-Production Services</h3>
        </div>
        <div className={ servicesStyles.postProdText }>
            <p>We offer a collaborative workflow in post production by combining our aim of technical excellence and creative vision of every project. Incorporating a post-production assessment during pre-production is the key in this process until the final master is delivered.</p>
            <ul>
                <li>Digital Laboratory</li>
                <li>Offline Facility</li>
                <li>DI Online</li>
                <li>Offline Editing</li>
            </ul>
        </div>
    </div>
    <div className={ servicesStyles.DCPSection }>
        <div className={ servicesStyles.title }>
            <h3>Digital Cinema Package Services</h3>
        </div>
        <div className={ servicesStyles.DCPText }>
            <p>Digital Cinema Package (DCP) is an absolute necessity for content delivery in theatrical distribution. DCP connects the film producers and distributors to the future of digital media creation and distribution. And the future has arrived.</p>
            <h5>What Digital Cinema Services are being offered?</h5>
            <ul>
                <li>DCP Mastering</li>
                <li>DCP Encryption</li>
                <li>DCP Cut to MTRCB Rating</li>
                <li>DCP Testing</li>
                <li>DCP Replication</li>
                <li>DCP Distribution Logistics and Support</li>
                <li>Trailer to Feature DCP Hard Lock</li>
                <li>Key Delivery Message</li>
                <li>Local Film Festival DCP Technical Support</li>
                <li>International Film Festival DCP Tracking</li>
                <li>Technical Support</li>
            </ul>
        </div>
    </div>
    <div className={ servicesStyles.inFilmAdvertisingSection }>
        <div className={ servicesStyles.title }>
            <img src={ inFilmAdvertising } alt=""/>
            <h3>In-Film Advertising</h3>
        </div>
        <p>In-Film Advertising: a type of embedded marketing, provides a lot of benefits to the advertiser in promoting his brand, products, and services.</p>
        <div className={ servicesStyles.productPlacement }>
            <h5>Product Placement</h5>
            <p>In-film product placement is an advertising technique that should be used by advertisers to promote their products through appearances in film, television, and digital. This ad technique works well because brands are able to reach a captive and engaged audience.</p>
            <p>It is also cost-effective as advertisers benefit from spin-off repetitive use of such material for in-store, on premise, collateral or digital exposure.</p>
        </div>
        <div className={ servicesStyles.brandIntegration }>
            <h5>Brand Integration</h5>
            <p>Of wider exposure in in-film advertising is brand integration where the product or company name becomes part of the show in such a way that it contributes to the narrative and creates an environment of brand awareness beyond that produced by the product placement.</p>
        </div>
        <div className={ servicesStyles.keyBenefits }>
            <h5>Key Benefits</h5>
            <p>Studies have identified key benefits of in-film advertising for clients:</p>
            <ol>
                <li>In-film advertising makes a lesser known brand become top of mind.</li>
                <li>Product placement can get audiences to associate a product with a certain lifestyle. Lifestyle brands such as apparel, food and beverages, gadgets and accessories will definitely benefit from movie exposure.</li>
                <li>Product placement can make audiences identify with the brands. Moviegoers appreciate the feeling of fantasy, closeness, and sentimentality.</li>
                <li>In-film advertising helps fund the moviemakers, notably indie filmmakers, thereby promoting a healthier movie industry and hopefully a better celluloid output.</li>
            </ol>
        </div>
        <div className={ servicesStyles.summary }>
            <h5>Summary</h5>
            <p>Brands should consider using in-film advertising in promoting their products and services. In-film advertising is most useful in increasing brand awareness, or introducing a new product or service, or aiming for brand dominance.</p>
            <p>Both explicit and implicit benefits abound. Explicitly, in-film advertising leads to higher brand recall and conscious awareness of the brand. More importantly, implicitly, it influences attitudinal and behavioral changes such as higher purchase intention, positive outlook towards the product or brand, and it can even lead to an exclusion of alternate or competitor brands from the mindset of the audience.</p>
        </div>
    </div>
    <div className={ servicesStyles.otherServices }>
        <div className={ servicesStyles.title }>
            <h3>Other Services</h3>
        </div>
        <div className={ servicesStyles.listOfServices }>
            <div className={ servicesStyles.column }>
                <div className={ servicesStyles.service }>
                    <img src={ contentCreation } alt=""/>
                    <h3>Content Creation</h3>
                </div>
                <div className={ servicesStyles.service }>
                    <img src={ contentBranding } alt=""/>
                    <h3>Content Branding</h3>
                </div>
            </div>
            <div className={ servicesStyles.column }>
                <div className={ servicesStyles.service }>
                    <img src={ talentCasting } alt=""/>
                    <h3>Talent Casting</h3>
                </div>
                <div className={ servicesStyles.service }>
                    <img src={ eventMarketing } alt=""/>
                    <h3>Event Marketing</h3>
                </div>
            </div>
        </div>
    </div>
  </Layout>
)

export default ServicesPage